import * as Storage from '@common/storage';
import { getPublisher, getPublisherConfig } from '../nats/nats';
import { NATS_LOGGING_CHANNEL } from '../constants';

const publisher = (() => {
    const _queue = []; // store log messages in array until NATS has initialized
    let _isInitialized = false;
    let _publish = (...logArgs) => {
        // If we've collected 50 messages before NATS has initialized, start dumping the older ones
        if (_queue.length > 49) {
            _queue.shift();
        }
        _queue.push(logArgs);
    };

    return {
        get isInitialized() {
            return _isInitialized;
        },
        get publish() {
            return _publish;
        },
        initialize: pubInstance => {
            if (_isInitialized) {
                return;
            }
            _publish = pubInstance;
            _queue.forEach(logArgs => _publish(...logArgs));
            _isInitialized = true;
        }
    };
})();

const retryInterval = 5000;
let nRetries = 1;

const getEnv = () => {
    const host = window.location.host;
    const subdom = host.split('.')[0].toLowerCase();

    if (!subdom.includes('-')) {
        return 'Production';
    }

    if (subdom.includes('dev')) {
        return 'Dev';
    }

    if (subdom.includes('demo')) {
        return 'Demo';
    }

    return 'QA';
};

// configure publisher.
const _getPublisher = () => {
    const publisherConfigKey = 'NatsLogger-browser.logging.app';

    if (!publisher.isInitialized) {
        getPublisherConfig(publisherConfigKey).then(conf => {
            //console.log(conf);
            publisher.initialize(
                getPublisher({
                    channel: NATS_LOGGING_CHANNEL,
                    flatten: true,
                    dedupeWindowMs: conf.DedupeWindowMs,
                    throttle: conf.Throttle
                })
            );
        });

        setTimeout(_getPublisher, nRetries++ * retryInterval);
    }
};

_getPublisher();

const LogLevelEnum = {
    INFO: 0,
    WARN: 1,
    ERROR: 2,
    CRITICAL: 3
};

const INFO_PREFIX = '[INFO]';

const WARN_PREFIX = '[WARN]';

const ERROR_PREFIX = '[ERROR]';

const CRITICAL_PREFIX = '[CRITICAL]';

const TECHNOLOGY_TAG = 'TECHNOLOGY';
const STACK_TRACE_TAG = 'STACK_TRACE';

let envName;
let consoleLog = true;

const getEnvName = () => {
    envName = envName ? envName : getEnv();
    return envName;
};

const _getMessageOptions = messageOptions => {
    messageOptions = messageOptions || { Tags: [] };
    messageOptions.Tags = messageOptions.Tags || [];
    return messageOptions;
};

const _hasStackTrace = messageOptions => {
    return messageOptions.Tags.filter(t => t.startsWith(STACK_TRACE_TAG)).length > 0;
};

/**
 *
 * Report and log an error with optional additional context
 *
 * reportError(error, context, messageOptions);
 *
 * @param {Error|string} error - An Error or a custom error message to be reported and logged
 * @param {Error|string|Object} context - Any additional context that might be relevent to the report (data object, caught Error, etc)
 * @param {Object} messageOptions - Optional configuration object for the log event
 * @param {LogPriorityEnum} messageOptions.Priority - Override the priority of the log event
 * @param {Array.<string>} messageOptions.Tags - Additional tags to attach to the log event
 *
 */
const reportError = (error, context, messageOptions) => {
    let exception;

    if (typeof error === 'string') {
        exception = new Error(error);
    } else {
        exception = error;
    }

    let msg = exception.message || exception || 'NA';
    let stack = exception.stack || 'NA';

    const derivedContext = context ?? exception.cause;

    if (derivedContext) {
        if (derivedContext instanceof Error) {
            msg = `${msg} -- ${derivedContext.toString()}`;
        } else if (typeof context === 'string') {
            msg = `${msg} -- ${derivedContext}`;
        } else {
            msg = `${msg}\n${JSON.stringify(derivedContext, null, 2)}`;
        }
    }

    messageOptions = _getMessageOptions(messageOptions);

    messageOptions.Tags.push(`${STACK_TRACE_TAG}:${stack}`);

    logError(msg, messageOptions);
};

/**
 *
 * @param {Object} messageOptions - Optional configuration object for the log event
 * @param {LogPriorityEnum} messageOptions.Priority - Override the priority of the log event
 * @param {Array.<string>} messageOptions.Tags - Additional tags to attach to the log event
 */

const _noStackTag = `${STACK_TRACE_TAG}:NA`;
const logInfo = (msg, messageOptions = null) => {
    const priority = messageOptions && messageOptions.Priority ? messageOptions.Priority : LogPriorityEnum.Low;

    const _msg = `${INFO_PREFIX}:[${getEnvName()}]: ${msg}`;

    messageOptions = _getMessageOptions(messageOptions);
    if (!_hasStackTrace(messageOptions)) {
        messageOptions.Tags.push(_noStackTag);
    }

    logMsg(_msg, messageOptions, LogLevelEnum.INFO, priority);
};

const logWarning = (msg, messageOptions = null) => {
    var priority = messageOptions && messageOptions.Priority ? messageOptions.Priority : LogPriorityEnum.Standard;

    var _msg = `${WARN_PREFIX}:[${getEnvName()}]: ${msg}`;

    messageOptions = _getMessageOptions(messageOptions);
    if (!_hasStackTrace(messageOptions)) {
        messageOptions.Tags.push(_noStackTag);
    }

    logMsg(_msg, messageOptions, LogLevelEnum.WARN, priority);
};

const logError = (msg, messageOptions = null) => {
    var priority = messageOptions && messageOptions.Priority ? messageOptions.Priority : LogPriorityEnum.High;

    var _msg = `${ERROR_PREFIX}:[${getEnvName()}]: ${msg}`;

    messageOptions = _getMessageOptions(messageOptions);
    if (!_hasStackTrace(messageOptions)) {
        messageOptions.Tags.push(_noStackTag);
    }

    logMsg(_msg, messageOptions, LogLevelEnum.ERROR, priority);
};

const logCritical = (msg, messageOptions = null) => {
    var priority = messageOptions && messageOptions.Priority ? messageOptions.Priority : LogPriorityEnum.VeryHigh;

    var _msg = `${CRITICAL_PREFIX}:[${getEnvName()}]: ${msg}`;

    messageOptions = _getMessageOptions(messageOptions);
    if (!_hasStackTrace(messageOptions)) {
        messageOptions.Tags.push(_noStackTag);
    }

    logMsg(_msg, messageOptions, LogLevelEnum.CRITICAL, priority);
};

const _techTag = `${TECHNOLOGY_TAG}:javascript`;

const logMsg = (msg, messageOptions, logLevel, priority) => {
    var failed = false;
    const logItem = {
        LogLevel: logLevel,
        LogPriority: priority,
        Message: msg,
        MessageType: 3
    };

    if (messageOptions && messageOptions.Tags) {
        logItem.Tags = messageOptions.Tags;
    } else {
        logItem.Tags = [];
    }

    if (window.location && window.location.href) {
        logItem.Tags.push(`RequestPath:${window.location.href}`);
    }
    if (document && document.referrer) {
        logItem.Tags.push(`Referrer:${document.referrer}`);
    }

    const breadCrumbBuffer = Storage.get(BREADCRUMB_CACHE_KEY);
    if (breadCrumbBuffer && breadCrumbBuffer.length > 0) {
        logItem.Tags.push(`BreadCrumb:${breadCrumbBuffer.join('^')}`);
    }

    logItem.Tags.push(_techTag);

    try {
        publisher.publish(logItem);
    } catch (error) {
        console.error(error);
        failed = true;
    } finally {
        if (consoleLog || failed) {
            if (logLevel > 1) console.error(msg);
            else console.log(msg);
        } else if (logLevel == LogLevelEnum.CRITICAL) {
            console.error(msg);
        }
    }
};

const LogPriorityEnum = {
    NotSet: 0,
    Low: 1,
    Standard: 2,
    High: 3,
    VeryHigh: 4
};

const BREAD_CRUMB_LEN = 10;
const BREADCRUMB_CACHE_TTL = 1200;
const BREADCRUMB_CACHE_KEY = 'logging-breadcrumb-key';
const addCurrentLocationToBreadCrumb = () => {
    if (window.location && window.location.href) {
        let breadCrumbBuffer = Storage.get(BREADCRUMB_CACHE_KEY);
        if (!breadCrumbBuffer) {
            breadCrumbBuffer = [];
        }

        breadCrumbBuffer.push(window.location.href);

        if (breadCrumbBuffer.length > BREAD_CRUMB_LEN) {
            breadCrumbBuffer.shift();
        }

        Storage.set(BREADCRUMB_CACHE_KEY, breadCrumbBuffer, BREADCRUMB_CACHE_TTL);
    }
};

export { logInfo, logWarning, logError, logCritical, reportError, LogPriorityEnum, addCurrentLocationToBreadCrumb };
