// browser polyfills
import 'core-js/stable';
import 'whatwg-fetch';
import 'intersection-observer';
import 'scroll-restoration-polyfill';
import 'fastestsmallesttextencoderdecoder';

import { FIELD_LEVEL_WINDOW_KEY, BOOTSTRAP_WINDOW_KEY } from '@common/constants';

import { init as performanceInit } from './telemetry/performance';
import { getRawParsedResult } from './userAgent';
import supportedBrowsers from './browsers';
import { httpFallbackWarnLogger } from './logging/fallback';
import { logEvent, logEventLegacy, AnalyticsTypeEnum } from './telemetry/NatsAnalytics';
import {
    logInfo,
    logWarning,
    logCritical,
    reportError,
    LogPriorityEnum,
    addCurrentLocationToBreadCrumb
} from './logging/NatsLogger';

const Bootstrap = {
    logEvent,
    logEventLegacy,
    logInfo,
    logWarning,
    logCritical,
    reportError,
    addCurrentLocationToBreadCrumb,
    LogPriorityEnum,
    AnalyticsTypeEnum
};

window[FIELD_LEVEL_WINDOW_KEY][BOOTSTRAP_WINDOW_KEY] = Bootstrap;

function browserErrorHandler(event) {
    const error = event.error;

    // React Error Boundaries have a peculiar behavior in that any errors that they intercept/catch will
    // be bubbled up to the "global" (window) event handler in Development mode, but not in Production. In order
    // to maintain consistency between dev/prod with regard to error reporting, we're detecting these specific errors,
    // and opting not to report them here.

    // Docs: https://react.dev/reference/react/Component#componentdidcatch-caveats
    // Github discussion: https://github.com/facebook/react/issues/10474
    if (error.stack && error.stack.includes('invokeGuardedCallbackDev')) {
        return;
    }

    reportError(error);
}

function browserRejectionHandler(event) {
    const error = (event && event.reason) || 'Unknown unhandled promise rejection';
    reportError(error);
}

function init() {
    if (!supportedBrowsers.test(navigator.userAgent)) {
        const parsed = getRawParsedResult();
        httpFallbackWarnLogger(`Detected an unsupported browser\n\n${JSON.stringify(parsed, null, 2)}`);
    }

    // Listen to uncaught errors
    window.addEventListener('error', browserErrorHandler);
    // Listen to uncaught promises rejections
    window.addEventListener('unhandledrejection', browserRejectionHandler);
}

init();
performanceInit();
